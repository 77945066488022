@screen lg {
  .list-navigation {
    /* padding-left: 125px; */
    /* padding-right: 75px; */
  }
}

.menus-height {
  min-height: calc(100vh - 54px);
  max-height: calc(100vh - 54px);
  overflow-y: auto;
}

@screen lg {
  .menus-height {
    min-height: unset;
    max-height: unset;
    overflow-y: visible;
  }
}

.menu-active {
  transform: translateX(100vw);
}

@screen lg {
  .menu-active {
    transform: translateX(0);
    transition: none;
  }
}

.menus {
  width: 28px;
  height: 22px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.menus span {
  display: block;
  position: absolute;
  height: 4px;
  width: 50%;
  background: #000;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.menus span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

.menus span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

.menus span:nth-child(1),
.menus span:nth-child(2) {
  top: 0px;
}

.menus span:nth-child(3),
.menus span:nth-child(4) {
  top: 9px;
}

.menus span:nth-child(5),
.menus span:nth-child(6) {
  top: 18px;
}

.menus-open span:nth-child(1),
.menus-open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menus-open span:nth-child(2),
.menus-open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menus-open span:nth-child(1) {
  left: 3px;
  top: 5px;
}

.menus-open span:nth-child(2) {
  left: calc(50% - 3px);
  top: 5px;
}

.menus-open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.menus-open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.menus-open span:nth-child(5) {
  left: 3px;
  top: 13px;
}

.menus-open span:nth-child(6) {
  left: calc(50% - 3px);
  top: 13px;
}

/** submenu */
.submenu .submenu-content {
  display: none;
  visibility: hidden;
  opacity: 0;
}

@screen lg {
  .submenu:hover .submenu-content {
    display: block;
    visibility: visible;
    opacity: 1;
  }
}

.submenu .icon-chevron {
  @apply transition-transform ease-in transform rotate-0 text-lg font-bold duration-200;
}

.submenu.opened .icon-chevron {
  @apply transform rotate-180;
}

@screen lg {
  .submenu:hover .icon-chevron {
    @apply transform rotate-180;
  }
}

.submenu.opened .submenu-content {
  visibility: visible;
  opacity: 1;
  display: block;
}

/* .submenu-content li:not(:last-child) {
  padding-bottom: 0.5rem
} */

.submenu-content {
  @apply relative ml-0 mt-0 bg-none shadow-none py-2;
}


@screen lg {
  .submenu-content {
    margin-top: 64px;
    margin-right: -1rem;
    transition: all 0.5s ease;
    min-width: 100vw;
    border-radius: 12px;
    @apply fixed top-0 bg-gray-1 w-auto text-left border border-transparent z-30 py-5 pt-0 shadow-pina;
  }
}